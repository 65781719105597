import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/core/auth/_services/token-storage.service';
import { AcceptRejectNegotiation } from 'src/app/shared/models/accept-reject-negotiation';
import { Draft } from 'src/app/shared/models/draft';
import { DraftService } from 'src/app/shared/services/draft.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'kt-accept-negotation',
  templateUrl: './accept-negotation.component.html',
  styleUrls: ['./accept-negotation.component.scss']
})
export class AcceptNegotationComponent implements OnInit {

  constructor(public  dialogRef:  MatDialogRef<AcceptNegotationComponent>,
    @Inject(MAT_DIALOG_DATA) public  data:  any,
    private draftServive : DraftService,
    private toastr: ToastrService,
    public sharedService:SharedService,
    private router: Router,
    private tokenStorageService :TokenStorageService
    ) {
 }

 alignType: any = [
  {value: 1, viewValue: 'Left'},
  {value: 2, viewValue: 'Right'},
  {value: 3, viewValue: 'Center'},

];
 form: AcceptRejectNegotiation = new AcceptRejectNegotiation();
 fileExtention;
 fileUrl;
  ngOnInit(): void {
    this.form.processTemplateReqRelId = this.data.processTemReqId;
    this.form.processId = this.data.processId;
    this.form.userMail = this.tokenStorageService.getUser().email;
    this.form.partyName = this.data.partyName;
    this.form.templateTypeName = this.data.documentTypeName;
    this.form.confirmWithCommercial = false;
  }
  onFileSelect(event):void{
	if(event.target.files.length > 0){
	  const file = event.target.files[0];

    if(file.type =="application/pdf" || file.type =="application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    {   
      this.form.file = file;
      
    }

      else
      {
        this.toastr.error("You can only upload Pdf / Word document (.docx) 😟" , "Warning!");
        this.form.file =  null;
        return;
      }



	 
	   var ext = file.name.split('.').pop();
      this.fileExtention = ext;
	  var reader = new FileReader();
	  reader.readAsDataURL(event.target.files[0]);

	  reader.onload = (_event) => {
		   this.fileUrl = reader.result;
	  }
	}

  }
  confirm(){

    if(this.validateDocument() === true )
    {
      this.sharedService.sendClickEvent();
      this.draftServive.AcceptNegotiation(this.form).subscribe(res =>{
      this.toastr.success("Negotiation confirmed successfully 😀" , "Success!");
      this.router.navigate(['negotiation/pending-negotiations']);
    },error=>{
      this.toastr.error("Failed to confirm Negotiation 😟" , "Error!");
      this.router.navigate(['negotiation/pending-negotiations']);
    });
    this.dialogRef.close();
  }
  }
  confirmWithCommercial(){
    this.form.confirmWithCommercial = true;
    this.confirm();
  }
  validateDocument(){
    if(this.form.file != undefined){
    if(this.fileExtention === "docx" || "doc" ){
      return true;
    }
    else{
      this.toastr.error("file should be only word document 😲");
      return false;

    }
  }else{
    this.toastr.error("please upload file 😲");
    return false;
  }
  }
  CloseDialog()
  {
    this.dialogRef.close();
  }
}
