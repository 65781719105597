// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, Login, Register, User } from '../../../../core/auth';
import { AuthService } from 'src/app/core/auth/_services/auth.service';
import { TokenStorageService } from 'src/app/core/auth/_services/token-storage.service';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
/**
 * ! Just example => Should be removed in development
 */

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  // Public params
  loginForm: FormGroup;
  loading = false;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];
  // apiResponse: string;
  private unsubscribe: Subject<any>;

  private returnUrl: any;

  // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  title = 'Login by your microsoft account';
  constructor(
    private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private authService: MsalService
  ) {
    this.unsubscribe = new Subject();
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.initLoginForm();

    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params.returnUrl;
    });



    this.authService.handleRedirectObservable().subscribe(res => {

      if (res != null && res.account != null) {
        this.authService.instance.setActiveAccount(res.account)
      }
      else
        this.LoginWithMicrosoft();

      if(this.isLoggedIn)
      this.CheckUserAndGenerateToken(true,this.authService.instance.getActiveAccount().username);

    })
  }


  isLoggedIn(): boolean {

    return this.authService.instance.getActiveAccount() != null
  }


  LoginWithMicrosoft() {
     this.authService.loginRedirect();

    // this.authService.loginPopup()
    //   .subscribe((response: AuthenticationResult) => {
    //     this.authService.instance.setActiveAccount(response.account);

    //     if (this.isLoggedIn && response.account.username != null)
    //       this.CheckUserAndGenerateToken(true, response.account.username, response.accessToken);

    //   });
  }




  logout() {
    this.authService.logout()
  }



  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initLoginForm() {
    // // demo message to show
    // if (!this.authNoticeService.onNoticeChanged$.getValue()) {
    // 	const initialNotice = `Use account
    // 	<strong>${DEMO_PARAMS.EMAIL}</strong> and password
    // 	<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
    // 	this.authNoticeService.setNotice(initialNotice, 'info');
    // }

    this.loginForm = this.fb.group({
      Email: ['', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(3),
        Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
      ])
      ],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])
      ]
    });
  }

  /**
   * Form Submit
   */


  CheckUserAndGenerateToken(IsAuthByMicrosoft, userName) {

    const controls = this.loginForm.controls;
    const _user: User = new User();

    if (IsAuthByMicrosoft == false) {

      /** check form */
      if (this.loginForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        return;
      }

      this.loading = true;
      // _user.clear();
      _user.email = controls.Email.value;
      _user.password = controls.password.value;
      _user.isAuthByMicrosoft = false;
    }
    else {
      _user.userName = userName;
      _user.isAuthByMicrosoft = true;
    }



    _user.roles = [];
    this.auth.login(_user).subscribe(
      data => {
        this.loading = false;
        this.authNoticeService.setNotice(this.translate.instant('AUTH.REGISTER.SUCCESS'), 'success');
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.saveUser(data);
        this.store.dispatch(new Login({ authToken: data.accessToken }));
        if (this.returnUrl === "/" || this.returnUrl == undefined) {
          //this.router.navigateByUrl("/search/global-serach");
          this.router.navigateByUrl("/Archiving/Statistics");

        } else {
          this.router.navigateByUrl(this.returnUrl);
        }
      },
      err => {
        this.loading = false;

        if (IsAuthByMicrosoft == true) {
          this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN_By_Microsoft') + ' (' + userName + ')', 'danger');

        }
        else
          this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');

      }
    );
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
