export class AcceptRejectNegotiation{
    file:any;
    templateReqRelId:number;
    comment:string;
    processTemplateReqRelId:number;
    processId :number;
    userMail:string;
    templateTypeName:string;
    partyName:string;
    alignment:number;
    confirmWithCommercial:boolean;
    nextStep:number;

}