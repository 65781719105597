import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/core/auth/_services/token-storage.service';
import { environment } from 'src/environments/environment';
import { AcceptRejectNegotiation } from '../models/accept-reject-negotiation';
import { ApprovalForm } from '../models/approval-form';
import { ApprovalModel } from '../models/approval-model';
import { ApprovalRequestDetailsVM } from '../models/approval-request-details-vm';
const ApprovalCaseStatusURL = environment.contratoApiUrl+'Approval/GetApprovalsStatus';
const  SearchApprovalsURL = environment.contratoApiUrl+'Approval/GetApprovals';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})


export class ApprovalService {

  constructor(private http: HttpClient,private tokenStorageService :TokenStorageService) { }
 
  GetAllApprovalCases(): Observable<any> {
    return this.http.get(ApprovalCaseStatusURL,httpOptions);
  }

  SearchApprovals(approval: ApprovalForm): Observable<ApprovalModel[]>
  {
     
    let startDate =(approval.startDate != undefined) ?moment(approval.startDate).format('YYYY/MM/DD'): '';
    let endDate = (approval.endDate != undefined) ?moment(approval.endDate).format('YYYY/MM/DD'): '';

    return this.http.get<ApprovalModel[]>(SearchApprovalsURL+`?status=${approval.status}&startDate=${startDate}&endDate=${endDate}` ,httpOptions);
  }

  GetApprovalRequestDetails(draftId:number) : Observable<ApprovalRequestDetailsVM[]>
  {
    return this.http.get<ApprovalRequestDetailsVM[]>(environment.contratoApiUrl+'Approval/GetApprovalRequestDetails'+`?draftId=${draftId}` ,httpOptions);
  }

  GetNegotiationsVersions(draftId:number) : Observable<any>
  {
    return this.http.get<any>(environment.contratoApiUrl+'Approval/GetNegotiationsVersions/'+draftId ,httpOptions);
  }

  GetInitializers() 
  {
    return this.http.get<any>(environment.contratoApiUrl+'Approval/GetInitializersUsers',httpOptions);
  }
AddApprovalUser(draftId:number, userId:number)
{
  return this.http.post<any>(environment.contratoApiUrl+'Approval/AddApprovalUser'+`?draftId=${draftId}&userId=${userId}`,httpOptions);
}

GetApprovalUsers(draftId:number) : Observable<any>
  {
    return this.http.get<any>(environment.contratoApiUrl+'Approval/GetApprovalUsers/'+draftId ,httpOptions);
  }

  ApproveRequestByMail(guid:string,  userId:number,  routeType:string,  rejectedNotes:string): Observable<any>
{
  var userConnectionId = this.tokenStorageService.getUserConnectionId();
  return this.http.post<any>(environment.contratoApiUrl+'Approval/ApproveRequestByMail/'+guid+`?userId=${userId}&routeType=${routeType}&rejectedNotes=${rejectedNotes}&userConnectionId=${userConnectionId}`,httpOptions);
}

RejectRequestByMail(guid:string,  userId:number,  routeType:string,  rejectedNotes:string): Observable<any>
{
  var userConnectionId = this.tokenStorageService.getUserConnectionId();
  return  this.http.post<any>(environment.contratoApiUrl+'Approval/RejectRequestByMail/'+guid+`?userId=${userId}&routeType=${routeType}&rejectedNotes=${rejectedNotes}&userConnectionId=${userConnectionId}`,httpOptions);
};

ResendMail( draftId:number,userId:number,  email:string): Observable<any>
{
  return this.http.post<any>(environment.contratoApiUrl+'Approval/ResendMail/'+`?draftId=${draftId}&userId=${userId}&email=${email}`,httpOptions);
}


DeleteApprovalUser(draftId:number, userId:number)
{
  return this.http.post<any>(environment.contratoApiUrl+'Approval/DeleteApprovalUser'+`?draftId=${draftId}&userId=${userId}`,httpOptions);
}

ReplaceNegotiationDocument(model : AcceptRejectNegotiation): Observable<any>{
  var userId = this.tokenStorageService.getUser().id;
  const formData = new FormData();
      formData.append('document', <File>model.file);
      formData.append('comment', model.comment);
      formData.append('procTempReqId', ''+model.processTemplateReqRelId+'');
      formData.append('userId', userId); 
      formData.append('processId',''+ model.processId+'');
     // formData.append('userMail',''+ model.userMail+'');
      // formData.append('templateTypeName',''+ model.templateTypeName+'');
      // formData.append('partyName',''+ model.partyName+'');
      //formData.append('alignment',''+ model.alignment+'');

  return this.http.post(environment.contratoApiUrl+'Approval/ReplaceDocument', formData);
}

}
