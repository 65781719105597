export const environment = {
    production: false,
    staging: true,
    isMockEnabled: true, // You have to switch this, when your real back-end is done
      authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
    
      
      contratoApiUrl:'https://ContratoAPIstaging.Esaal.me/api/',
      staticFilesUrl:'https://ContratoAPIstaging.Esaal.me/',
      archivingStaticFilesUrl:'https://ContratoAPIstaging.Esaal.me/storage/',
      WebsiteUrl: 'https://contratostaging.esaal.me',
      clientId: 'e97d6769-3386-4ba8-a254-f96e9b535e6b',
      signalRUrl:'https://contratostagingapi.arpuplus.com/inform',
      password:"@Esaal23@#$",
    
  
    };
    