import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StagingBaseURL } from './global/global-config';
import { Observable } from 'rxjs';
import { User } from '../_models/user.model';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
// tslint:disable-next-line:whitespace
const RegisterURL = environment.contratoApiUrl + 'Login/AddUser';
@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {
  }
  login(user: User): Observable<any> {
    return this.http.post(RegisterURL, {

      // Name: user.Name,
      // Email : user.email,
      email: user.email,
      // accessToken: user.accessToken,
      password: user.password,
      userName: user.userName,
      isAuthByMicrosoft: user.isAuthByMicrosoft,
      pass: environment.password
    }, httpOptions);
  }

  requestPassword(email: string): Observable<any> {
    return this.http.get<any>(environment.contratoApiUrl + 'Login/ForgetPassword/' + email, httpOptions);
  }

}
