// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { AcceptBusinessCaseComponent } from './business-case/accept-business-case/accept-business-case.component';
import { RejectBusinessCaseComponent } from './business-case/reject-business-case/reject-business-case.component';
import { AcceptNegotationComponent } from './negotiation/accept-negotation/accept-negotation.component';
import { RejectNegotiationComponent } from './negotiation/reject-negotiation/reject-negotiation.component';
import { LoginComponent } from './views/pages/auth/login/login.component';
import { AuthGuard, ModuleGuard } from './core/auth';
import { Error3Component } from './views/pages/error/error3/error3.component';
import { StatisticsComponent } from './archiving/statistics/statistics.component';
import { ReplaceDocumentComponent } from './approval/replace-document/replace-document.component';
import { AcceptRejectDraftComponent } from './draft/accept-reject-draft/accept-reject-draft.component';

const routes: Routes = [
  {path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},
  {path: 'error', loadChildren: () => import('./views/pages/error/error.module').then(m => m.ErrorModule)},
  {

    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'party',
        loadChildren:()=>import('./party/party.module').then(m=>m.PartyModule)
      },
      {
        path: 'draft',
        loadChildren: () => import('./draft/draft.module').then(m => m.DraftModule),
      },
      {
        path: 'Approval',
        loadChildren: () => import('./approval/approval.module').then(m => m.ApprovalModule),
      },

      {
        path: 'Archiving',
        loadChildren: () => import('./archiving/archiving.module').then(m => m.ArchivingModule),
      },
      {
        path: 'businessCase',
        loadChildren: () => import('./business-case/business-case.module').then(m => m.BusinessCaseModule),
      },
      {
        path: 'error',
        loadChildren: () => import('../app/views/pages/error/error.module').then(m => m.ErrorModule),
      },
      {
        path: 'user-management',
        loadChildren: () => import('./user-managment/user-managment.module').then(m => m.UserManagmentModule),
      },
      {
        path: 'negotiation',
        loadChildren: () => import('./negotiation/negotiation.module').then(m => m.NegotiationModule),
      },
      {
        path: 'search',
        loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
      },
      {
        path: 'setting',
        loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule),
      },
      {
        path: 'reporting',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
      },


     // {path: '', redirectTo: '/auth/login', pathMatch: 'full'},
//  {path: '', redirectTo: '/Archiving/Statistics', pathMatch: 'full',canActivate:[AuthGuard]},
//  {path: '**', component: Error3Component, pathMatch: 'full'},

{path: '', redirectTo: '/Archiving/Statistics', pathMatch: 'full',canActivate:[AuthGuard]},


    ],

  },
  // {path: '', redirectTo: 'auth/login', pathMatch: 'full'},
   //{path: '**', redirectTo: 'auth/login', pathMatch: 'full'},
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
  entryComponents: [AcceptBusinessCaseComponent , RejectBusinessCaseComponent , AcceptNegotationComponent , RejectNegotiationComponent,ReplaceDocumentComponent, AcceptRejectDraftComponent]
})
export class AppRoutingModule {
}
