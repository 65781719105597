import { Company } from 'src/app/shared/models/company';
import { Process } from 'src/app/shared/models/process';
import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

export class User extends BaseModel {
  id: number;
  userName: string;
  password: string;
  email: string;
  accessToken: string;
  refreshToken: string;
  roles: number[];
  pic: string;
  name: string;
  occupation: string;
  companyName: string;
  phone: string;
  address: Address;
  socialNetworks: SocialNetworks;
  department: number;
  sendingAlert:boolean;
  isSupervisor: boolean;
  isActive:boolean;
  isAccountManager:boolean;
  manager:number;
  imagePath:string;
  activeProcess : Process;
  companies : Company[];
  isInitializer : boolean;
  signaturePath:string;
  fileName:string;
  type:string;
  isAuthByMicrosoft:boolean;

  clear(): void {
    // this.id = undefined;
    // this.userName = '';
    // this.password = '';
    // this.email = '';
    // this.roles = [];
    // this.name = '';
    // this.accessToken = 'access-token-' + Math.random();
    // this.refreshToken = 'access-token-' + Math.random();
    // this.pic = './assets/media/users/default.jpg';
    // this.occupation = '';
    // this.companyName = '';
    // this.phone = '';
    // this.address = new Address();
    // this.address.clear();
    // this.socialNetworks = new SocialNetworks();
    // this.socialNetworks.clear();
  }
}
