export class PermissionsTable {
  public static permissions: any = [
    {
      id: 1,
      name: 'accessToPartyModule',
      level: 1,
      title: 'Party module'
    },
    {
      id: 2,
      name: 'accessToUserManagmentModule',
      level: 1,
      title: 'User Management module'
    },
    {
      id: 3,
      name: 'accessToDraftModule',
      level: 1,
      title: 'Draft module'
    },
    {
      id: 4,
      name: 'accessToBusinessCaseModule',
      level: 1,
      title: 'Business Case module'
    },
    {
      id: 5,
      name: 'accessToNegotiationModule',
      level: 1,
      title: 'Negotiation module'
    },
    {
      id: 6,
      name: 'accessToApprovalModule',
      level: 1,
      title: 'Approval module'
    },
    {
      id: 7,
      name: 'accessToArchivingModule',
      level: 1,
      title: 'Approval module'
    },
    {
      id: 8,
      name: 'accessToReportingModule',
      level: 1,
      title: 'Reporting module'
    },
  ];
}
