<div class="card">
    <div class="card-header">
      <h1>Reject Negotation</h1>
    </div>
    <div class="card-body">

      <form class="form"  name="form" 
      (ngSubmit)="f.form.valid && confirm()"
      #f="ngForm"
      novalidate>
        <div class="form-group">
          <div class="row">
            <div class="form-group col-12">
              <label for="comment">Comment</label>
              <textarea class="form-control" required rows="3" name="comment"  #comment="ngModel" [(ngModel)]="form.comment"></textarea>
              <!-- <div
              class="text-muted" style="width:100%"
              *ngIf="f.submitted && !form.comment">
              Comment is required!
              </div> -->
              </div>
          </div>
          
<div class="row">
  <div class="col-xl-12 col-lg-12">
    <mat-form-field appearance="fill" >
  <mat-label>Rejection reason</mat-label>
   <mat-select name="nextStep"  #nextStep="ngModel" [(ngModel)]="form.nextStep" required>
   <mat-option *ngFor="let step of rejectionSteps" [value]="step.value" >
 {{step.name}}
 </mat-option>
 </mat-select>
 </mat-form-field>
</div>
</div>
          <div class="row">
            <div class="form-group col-12">
              <label for="file">Attach File</label>
            <input type="file" class="form-control-file"
            name="file"
            placeholder="upload document" (change)="onFileSelect($event)">
            <!-- <div
            class="text-muted" style="width:100%"
            *ngIf="f.submitted && !form.file">
            Attached File is required!
            </div> -->
            </div>
          </div>

        </div>
<div>
  <button type="submit" class="btn btn-success btns">Confirm</button>
  <button class="btn btn-danger btns btnsSide" (click)="dialogRef.close(false)">Cancel</button>
</div>
      </form>

    </div>

</div>

